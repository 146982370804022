/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteLabels, fetchLabels } from "../api/labelApi";
import { Label } from "../../../../models/label";
import { useNotification } from "../../../../utils/notification/notificationContext";
import {
    getFileExtension,
    PdfViewer,
} from "../inventory-data/request-shipment/requestOutboundShipmentModal";
import { LabelModal } from "../inventory-data/request-shipment/labelModal";
import {
    ActionButton,
    DeleteButton,
    SecondaryButton,
} from "../../../../components/buttons";

function ClientLabelsModal({
    openClientLabelsModal,
    setOpenClientLabelsModal,
}: {
    openClientLabelsModal: boolean;
    setOpenClientLabelsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { showNotification } = useNotification();
    const [labels, setLabels] = useState<Label[]>([]);

    const [selectedLabels, setSelectedLabels] = useState<Label[]>([]);

    const [refetchLabelData, setRefetchLabelData] = useState<number>(0);
    const handleLabelRefetch = () => {
        setRefetchLabelData((prevValue) => prevValue + 1);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchLabels();
            if (data.status === "success") {
                setLabels(data.data.labels);
            } else {
                showNotification(
                    "Failed to fetch labels",
                    data.message,
                    "error"
                );
            }
        };
        if (openClientLabelsModal || refetchLabelData > 0) {
            fetchData();
        }
    }, [openClientLabelsModal, refetchLabelData]);

    const [selectedViewingLabel, setSelectedViewingLabel] =
        useState<Label | null>(null);

    const [openLabelModal, setOpenLabelModal] = useState(false);
    const [selectedPagesByLabel, setSelectedPagesByLabel] = useState<{
        [labelId: string]: number[];
    }>({});

    useEffect(() => {
        if (selectedViewingLabel) {
            setOpenLabelModal(true);
        }
    }, [selectedViewingLabel]);

    useEffect(() => {
        if (!openLabelModal) {
            setSelectedViewingLabel(null);
        }
    }, [openLabelModal]);

    const [deleteLoading, setDeleteLoading] = useState(false);
    const handleDelete = async () => {
        setDeleteLoading(true);
        const data = await deleteLabels(selectedLabels.map((l) => l.label_id));
        if (data.status === "success") {
            setOpenClientLabelsModal(false);
            if (handleLabelRefetch) handleLabelRefetch();
            setSelectedLabels([]);
            showNotification("Deleted all selected labels.", "", "success");
        } else {
            showNotification("Failed to delete label", data.message, "error");
        }
        setDeleteLoading(false);
    };

    return (
        <div>
            <Dialog
                open={openClientLabelsModal}
                onClose={() => setOpenClientLabelsModal(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-20 w-screen">
                    <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 pr-4 pt-4">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setOpenClientLabelsModal(false)
                                    }
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900 pb-5"
                            >
                                My Labels
                            </DialogTitle>
                            <div className="w-full h-px bg-gray-200"></div>

                            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                                {selectedLabels.length < 1 ? (
                                    <ActionButton
                                        label="Select all"
                                        handleClick={() =>
                                            setSelectedLabels(labels)
                                        }
                                    />
                                ) : (
                                    <SecondaryButton
                                        label="Deselect all"
                                        handleClick={() =>
                                            setSelectedLabels([])
                                        }
                                    />
                                )}
                                <div className="flex flex-row space-x-4 overflow-scroll">
                                    {labels.map((label, index) => (
                                        <div
                                            onClick={() =>
                                                setSelectedViewingLabel(label)
                                            }
                                            key={index}
                                            className="pl-2 pt-4"
                                        >
                                            {getFileExtension(
                                                label.public_url
                                            ) === "pdf" ? (
                                                <>
                                                    <PdfViewer
                                                        url={label.public_url}
                                                        isSelected={
                                                            selectedLabels.length >
                                                            0
                                                        }
                                                    />
                                                    <span className="text-sm text-accent font-semibold">
                                                        {selectedPagesByLabel[
                                                            label.label_id
                                                        ]
                                                            ? `${
                                                                  selectedPagesByLabel[
                                                                      label
                                                                          .label_id
                                                                  ].length
                                                              } ${" selected"}`
                                                            : ""}{" "}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="border border-gray-300 cursor-pointer shadow-sm h-44 w-36 overflow-hidden rounded-md hover:border-accent transition-all hover:scale-105">
                                                        <img
                                                            src={
                                                                label.public_url
                                                            }
                                                        />
                                                    </div>
                                                    <span className="text-sm text-accent font-semibold">
                                                        {selectedPagesByLabel[
                                                            label.label_id
                                                        ]
                                                            ? `${
                                                                  selectedPagesByLabel[
                                                                      label
                                                                          .label_id
                                                                  ].length
                                                              } ${" selected"}`
                                                            : ""}{" "}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div>
                                {selectedLabels.length > 0 && (
                                    <DeleteButton
                                        label="Delete"
                                        handleClick={() => handleDelete()}
                                        loading={deleteLoading}
                                    />
                                )}
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
            <LabelModal
                selectedViewingLabel={selectedViewingLabel}
                openLabelModal={openLabelModal}
                setOpenLabelModal={setOpenLabelModal}
                selectedPages={
                    selectedPagesByLabel[
                        selectedViewingLabel?.label_id || ""
                    ] || []
                }
                updateSelectedPages={(pages) => {}}
                handleLabelRefetch={handleLabelRefetch}
                viewingOnly={true}
            />
        </div>
    );
}

export default ClientLabelsModal;
